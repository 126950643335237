import { url } from '@/api/base-url'

export function getTopTen (
  filters = {
    Id_func: '',
    id_sector: 0,
    search: ''
  }) {
  return url.get(`profissionais/topTen?idsFuncao=${filters.Id_func}&idSector=${filters.id_sector}&search=${filters.search}`)
}

export function getRecents (
  filters = {
    Id_func: '',
    id_sector: 0,
    search: ''
  }) {
  return url.get(`profissionais/recentes?idsFuncao=${filters.Id_func}&idSector=${filters.id_sector}&search=${filters.search}`)
}

export function getProfissionais (
  filters = {
    Id_func: '',
    id_sector: 0,
    search: '',
    page: 1
  }) {
  return url.get(`profissionais/filtro?idsFuncao=${filters.Id_func}&idSector=${filters.id_sector}&search=${filters.search}&page=${filters.page}`)
}
