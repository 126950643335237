 <template>
  <base-container-wrapper-grid
  class="space-medium"
  :arraySlideInfo="arraySlideInfo">
    <template #swiper-card="{ item }">
      <swiper-card :item="item" @handler-profissional="getProfissionalData" />
    </template>
    <template #modals>
      <modal-details
        :id-from-list="modalData"
        v-if="showModal"
        @close="showModal = false"
      />
      <sign-in-out-modal
        v-if="confirmModal"
        @close="confirmModal = false"
        :modal-btn-continue="modalBtnContinue"
        :modal-title="modalText"
        :be-true="beTrue"
      />
    </template>
  </base-container-wrapper-grid>
</template>

<script>
import ModalDetails from '@/components shared/Modal/ProfissionalModalDetails'
import SignInOutModal from '@/components shared/Modal/ConfirmModalSignOut'
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'
import baseContainerWrapperGrid from './base-container-wrapper-grid'
import SwiperCard from './card'
export default {
  name: 'TopTenCard',
  props: {
    arraySlideInfo: {
      type: Array,
      default: () => []
    }
  },
  components: {
    'modal-details': ModalDetails,
    'sign-in-out-modal': SignInOutModal,
    'base-container-wrapper-grid': baseContainerWrapperGrid,
    'swiper-card': SwiperCard
  },
  mounted () {
    if (isAuthenticatedParceiro()) {
      this.verifyAuth = true
    }
  },
  methods: {
    getProfissionalData (id) {
      if (isAuthenticatedParceiro() && !isAuthenticated()) {
        this.modalData = id
        this.AddView(id)
        this.showModal = true
      } else if (!isAuthenticatedParceiro() && !isAuthenticated()) {
        this.modalText = 'Para ver detalhes do Profissional deve iniciar sessão como Parceiro. Deseja continuar?'
        this.modalBtnContinue = true
        this.confirmModal = true
        localStorage.setItem('@Rise_Parceiro_Home_Recents', 'yes')
      } else if (isAuthenticated() && !isAuthenticatedParceiro()) {
        this.modalText = 'Para ver detalhes do Profissional deve ter sessão iniciada como Parceiro. Tem certeza que pretender terminar a sessão actual?'
        this.modalBtnContinue = true
        this.confirmModal = true
        this.beTrue = false
        localStorage.setItem('@Rise_Parceiro_Home_Recents', 'yes')
      }
    },
    AddView (id) {
      this.$store.dispatch('AddVisualizacao', {
        idProfissional: id
      })
        .then(sucess => {
        })
        .catch(() => {
          this.error = true
        })
    }
  },
  data () {
    return {
      showModal: false,
      verifyAuth: false,
      confirmModal: false,
      beTrue: true,
      modalText: '',
      modalBtnContinue: false
    }
  }
}
</script>
