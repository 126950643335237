<template>
  <div class="container-filter" >
    <section class="description-wrapper" :class="{[themeHeader]: true}">
      <container-page>
        <div class="search">
          <div class="title">
            <slot name="title" />
          </div>
          <slot name="dropdown" />
        </div>
        <slot name="description-title" />
      </container-page>
    </section>
    <section class="slide-wrapper" :class="{[themeBody]: true}">
      <container-page>
        <slot name="slide-wrapper" />
      </container-page>
    </section>
  </div>
</template>
<script>
import ContainerPage from '@/components shared/container-card.vue'
export default {
  name: 'base-container-wrapper-filter',
  props: {
    themeHeader: {
      type: String,
      default: 'primary-color'
    },
    themeBody: {
      type: String,
      default: 'pattern-color'
    }
  },
  components: {
    'container-page': ContainerPage
  }
}
</script>
<style lang="scss" scoped>
.primary-color {
  background-color: #26A0A5 !important;
}
.secondary-color {
  background-color: #FCB72D !important;
}
.pattern-color {
  background-color: #503660 !important;
}
.transparent {
  background-color: transparent !important;
}
  .container-filter{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .description-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: calc(10px + (20 - 10) * ((100vw - 768px) / (2048 - 768)));
    background-color: transparent;
    .search {
      padding: calc(15px + (30 - 15) * ((100vw - 768px) / (2048 - 768)));
      padding-left: 0;
      display: flex;
      color: #fff;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-size: calc(14px + (28 - 14) * ((100vw - 768px) / (2048 - 768)));
      }
      @media screen and (min-width: 768px){
      align-items: flex-end;
      flex-direction: row;
        .title {
          margin-right: calc(75px + (150 - 75) * ((100vw - 768px) / (2048 - 768)));
          font-size: calc(30px + (40 - 30) * ((100vw - 768px) / (2048 - 768)));
        }
      }
    }
  }
  .slide-wrapper{
    width: 100%;
    display: flex;
    padding: calc(15px + (30 - 15) * ((100vw - 768px) / (2048 - 768))) 0;
    background-color: #503660;
    @media screen and (min-width: 768px){
      padding: calc(40px + (80 - 40) * ((100vw - 768px) / (2048 - 768))) 0;
    }
  }

</style>
