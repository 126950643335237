<template>
  <div class="items">
    <span class="item" v-for="(item, index) of items" :key="index">
      <slot v-bind="item"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'group-button-radio',
  props: {
    items: Array
  }
}
</script>

<style lang="scss" scoped>
.items {
  display: flex;
  flex-direction: column;
  .item {
    flex-direction: row;
    margin: 4px 0;
  }
  @media screen and(min-width: 768px) {
    flex-direction: row;
    .item {
      margin: 0 4px;
      &:first-child {
      margin-left: 0;
    }
    }
  }

}
.col {
  flex-direction: column;
  .item {
    margin-left: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
