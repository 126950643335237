<template>
  <div class="home">
    <home-hero/>
    <container-page>
      <home-sectores :sectores="sectores" />
    </container-page>
    <section>
      <home-top-ten :sectores="sectores" />
    </section>
    <page-with-footer>
      <section><!-- bg-botton -->
          <home-recents :sectores="sectores" />
          <!--<home-testimoy />-->
          <app-testimony />
          <app-footer/>
      </section>
    </page-with-footer>
  </div>
</template>

<script>

import Hero from '@/components/Home/Hero.vue'
import Sectores from '@/components/Home/Sectores.vue'
import TopTen from '@/components/Home/TopTen.vue'
import Recents from '@/components/Home/Recent.vue'
import AppTestimony from '@/components/Home/Testimony.vue'
import Footer from '@/components shared/footer.vue'
import ContainerPage from '@/components shared/container-card.vue'
import PageWithFooter from '@/components shared/page-with-footer.vue'
import { getSectores } from '@/api/services/home-page'
export default {
  name: 'home',
  metaInfo: {
    title: 'Rise | Home'
  },
  components: {
    'home-hero': Hero,
    'home-sectores': Sectores,
    'home-top-ten': TopTen,
    'home-recents': Recents,
    'app-footer': Footer,
    'container-page': ContainerPage,
    'page-with-footer': PageWithFooter,
    'app-testimony': AppTestimony
  },
  data () {
    return {
      element: null,
      topElement: null,
      sectores: null
    }
  },
  created () {
    document.title = 'Rise'
  },
  methods: {
    goto () {
      const ScreenSize = window.screen.width
      if (localStorage.getItem('@Rise_Parceiro_Continue_Activite') === 'yes' && localStorage.getItem('@Rise_Parceiro_Home_TopTen') === 'yes') {
        window.scrollTo({ left: 0, top: 1550, behavior: 'smooth' })
        if (ScreenSize < 768) {
          window.scrollTo({ left: 0, top: 1750, behavior: 'smooth' })
        }
        localStorage.setItem('@Rise_Parceiro_Home_TopTen', '')
        localStorage.setItem('@Rise_Parceiro_Continue_Activite', '')
      } else if (localStorage.getItem('@Rise_Parceiro_Continue_Activite') === 'yes' && localStorage.getItem('@Rise_Parceiro_Home_Recents') === 'yes') {
        window.scrollTo({ left: 0, top: 2050, behavior: 'smooth' })
        if (ScreenSize < 768) {
          window.scrollTo({ left: 0, top: 2450, behavior: 'smooth' })
        }
        localStorage.setItem('@Rise_Parceiro_Home_Recents', '')
        localStorage.setItem('@Rise_Parceiro_Continue_Activite', '')
      }
    }
  },
  mounted () {
    getSectores().then(res => {
      this.sectores = res.data.object
    })
    setTimeout(() => {
      this.goto()
    }, 2000)
  }
}
</script>
<style lang="scss" scoped>
  .home{
    width: 100%;
    min-height: 70vh;
    box-sizing: border-box;
    max-width: 100vw;
  }
  .bg-center::after{
    content: '';
    background: transparent url('../assets/img/RODAPE2.svg') 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size:cover;
    position: absolute;
    margin-bottom: 700px;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
    transform: scaleX(1.3) scaley(1.1) translateX(-10%);
    opacity: 0.8;
  }
  .bg-center{
      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 90px;
      @media(max-width: 768px){
        height: auto!important;
      }
   }
  @media(max-width:1900px){
    .bg-center::after{
      content: '';
      background: transparent url('../assets/img/RODAPE2.svg') 0% 0% no-repeat padding-box;
      background-repeat: no-repeat;
      background-size:cover;
      position: absolute;
      width: 100%;
      max-width: 100vw;
      margin-top: 1100px;
      margin-bottom: 0;
      display: block;
      z-index: -2;
      left: -2%;
      transform: scaleX(auto) scaley(1.3) translateX(0);
    }
  }
</style>
