<template>
  <div tabindex="0" class="card" @focus="focus" @blur="blur" @touchmove="mouseleave" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <object type="image/svg+xml" class="icon" loading="lazy" width="70" height="70" :data="img" ref="icone"></object>
    <h3 class="title">{{areaName}}</h3>
    <span class="bagde" @click.stop="() => handlerProfissionalPage(areaName)">
      <strong>{{quantidade}}</strong>
      <span>{{ quantidade === 1 ? 'Profissional' : 'Profissionais' }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AreasCard',
  props: {
    areaName: {
      type: String,
      default: () => 'Vendas'
    },
    urlImage: {
      type: String,
      default: () => 'http://risebo-dev.digitalfactory.co.ao/Storage/Areas/Incones/brain.svg'
    },
    urlImageOver: String,
    quantidade: {
      type: Number,
      default: () => 500
    }
  },
  data () {
    return {
      img: this.urlImage,
      hover: false
    }
  },
  methods: {
    handlerProfissionalPage (areaName) {
      this.$router.push({ name: 'Candidate', query: { filter: areaName } })
    },
    focus () {
      if (window.screen.width > 768) return
      this.img = this.urlImageOver
      this.hover = true
    },
    mouseenter () {
      if (window.screen.width < 768) return
      this.img = this.urlImageOver
      this.hover = true
    },
    blur () {
      if (window.screen.width > 768) return
      this.img = this.urlImage
      this.hover = false
    },
    mouseleave (e) {
      if (window.screen.width < 768) return
      this.img = this.urlImage
      this.hover = false
      e.target.blur()
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #259FA5;

  width: 316px;
  height: 246px;
  .icon {
    width: 100px;
    height: 100px;
  }
   .title {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin: 0 20px;
    margin-top: 20px;
  }
  @media screen and (min-width: 975px) and (max-width: 1440px) {
    width: 170px;
    height: 190px;
    .icon {
      height: 75px;
      width: 75px;
    }
    .title {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1640px) {
    width: 263px;
    height: 200px;
    padding: 8px;
  }
  position: relative;
  object {
    pointer-events: none;
  }
  .bagde {
    cursor: pointer;
  }
  &:focus, &:hover {
    background-color: #FCB72D;
    transition: box-shadow .3s ease-out 0s;
    -webkit-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.404);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.432);
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.404);
    .title {
      color: #503660;
    }
    .bagde {
      display: flex;
    }
  }
  .bagde {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #503660;
    color: #FCB72D;
    border-radius: 40px;
    position: absolute;
    top: 20px;
    right: 30%;
    padding: 3px 10px;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.63);
    transform: translateY(-35%);
    z-index: 1;
    @media screen and (min-width: 500px){
      padding: 10px 20px;
      right: -25%;
      top: 40%;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 45%;
      border: 10px solid #503660;
      border-color: #503660 transparent transparent transparent;
      @media screen and (min-width: 500px){
        left: 25%;
      }
    }
    strong {
      margin: 0;
      padding: 0;
    }
    span {
      font-size: 20px;
    }
    @media screen and (min-width: 1000px) and (max-width: 1400px) {
      padding: 5px 10px;
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
