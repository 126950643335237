<template>
  <div class="business-cards">
    <section class="description-wrapper">
      <h1 class="title">ENCONTRE AQUI OS PROFISSIONAIS QUE A SUA EMPRESA PROCURA</h1>
      <p> Os RISERs são profissionais capacitados e certificados nas mais diversas áreas de actividade,
        preparados para o mercado de trabalho.
        Aqui encontrará uma vasta oferta de perfis: dos mais juniores ao mais seniores,
        com diferentes níveis de escolaridade, formação e experiência profissional. Conheça os Profissionais disponíveis!</p>
    </section>
    <base-container-wrapper-grid class="margin-large space-medium" :arraySlideInfo="sectores">
      <template #swiper-card="{ item }">
        <sectores-card v-if="item"
        :urlImageOver="item.iconeUrlFull_Over"
        class="card-sector"
        :url-image="item.iconeUrlFull === null ? require('@/assets/img/shopping-basket.svg') : item.iconeUrlFull "
        :area-name="item.nome"
        :quantidade="item.countProfissionais"
      />
      </template>
    </base-container-wrapper-grid>
  </div>
</template>

<script>

import BaseContainerWrapperGrid from '@/components/Home/base-container-wrapper-grid'
import SectoresCard from '@/components/Home/SectoresCard'
export default {
  name: 'BusinessCards',
  props: {
    sectores: Array
  },
  components: {
    'sectores-card': SectoresCard,
    'base-container-wrapper-grid': BaseContainerWrapperGrid
  },
  data () {
    return {
      setores: [],
      hoverIconColor: '#26a0a5'
    }
  },
  methods: {
    toogleEnter () {
      this.hoverIconColor = '#26a0a5'
    },
    toogleLeave () {
      this.hoverIconColor = '#26a0a5'
    }
  }
}
</script>

<style lang="scss" scoped>
  .business-cards{
    h1{
      font-family: "NexaBold", sans-serif;
      text-transform: uppercase;
    }
    .margin-large {
      margin: 30px 0;
    }
    p{
      font-family: "RubikLight", sans-serif;
      padding: 16px;
      color: inherit;
      text-align: center;
      font-size: 20px;
    }
  }
  .description-wrapper{
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #4B335A;
    .title {
      font-size: 36px;
      text-align: center;
    }
  }
  .cards-wrapper{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px 0 100px 0px;
    @media screen and (min-width: 400px) {
      justify-content: space-between;
    }
    .card-sector {
      margin: 10px 0;
    }
  }
</style>
