import { render, staticRenderFns } from "./list-item.vue?vue&type=template&id=3df6bb68&scoped=true&"
import script from "./list-item.vue?vue&type=script&lang=js&"
export * from "./list-item.vue?vue&type=script&lang=js&"
import style0 from "./list-item.vue?vue&type=style&index=0&id=3df6bb68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df6bb68",
  null
  
)

export default component.exports