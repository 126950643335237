<template>
  <base-container-wrapper-filter themeHeader="secondary-color" themeBody="transparent">
      <h1 slot="title">NOVOS<br />PROFISSIONAIS</h1>
    <template #dropdown>
      <select-dropdown
      placeholder="Seleccione um Sector"
      :dataSource="sectores"
      :value="funcaoValue"
      @setData="setFuncao"
      align="bottom"
      btnColor= '#503660'
      title="Profissionais recentemente integrados"/>
    </template>
    <template #slide-wrapper>
      <app-loading  v-show="stateLoading"/>
      <recent-card :array-slide-info="recentes" />
    </template>
  </base-container-wrapper-filter>
</template>

<script>
import Loading from '@/components/Form/loading'
import RecentCard from './RecentCard'
import SelectDropdown from '@/components/Home/list-item'
import { getRecents } from '@/api/services/filters'
import baseContainerWrapperFilter from './base-container-wrapper-filter'

export default {
  name: 'Recents',
  props: {
    sectores: Array
  },
  components: {
    'select-dropdown': SelectDropdown,
    'recent-card': RecentCard,
    'app-loading': Loading,
    'base-container-wrapper-filter': baseContainerWrapperFilter
  },
  mounted () {
    getRecents().then(resp => {
      this.recentes = resp.data.object
    })
  },
  methods: {
    getIdSector (id) {
      const _this = this
      this.stateLoading = true
      getRecents({ id_sector: id, Id_func: '', search: '' }).then(resp => {
        _this.stateLoading = false
        this.recentes = resp.data.object
      })
    },
    setFuncao (value) {
      this.funcaoValue = value.nome
      this.getIdSector(value.id)
    }
  },
  data () {
    return {
      recentes: [],
      funcaoValue: '',
      stateLoading: false
    }
  }
}
</script>

<style lang="scss" scoped>
  h1{
    text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    font-family: "NexaBold",sans-serif;
  }
</style>
