import { url } from '@/api/base-url'

export function getDepoimentos () {
  return url.get('depoimentos')
}

export function getSectores () {
  return url.get('sectores')
}

export function getTopTen (
  filters = {
    Id_func: 0
  }) {
  return url.get(`profissionais/topTen?idFuncao=${filters.Id_func}`)
}

export function getRecents (
  filters = {
    Id_func: 0
  }) {
  return url.get(`profissionais/recentes?idFuncao=${filters.Id_func}`)
}
