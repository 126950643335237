<template>
  <footer class="footer">
    <section class="footer-wrapper">
      <div class="logo-container">
        <div class="logo">
          <img src="@/assets/img/logo2.png" alt="" srcset="">
        </div>
      </div>
    </section>
    <section class="copyright-wrapper">
      <div>
        <p>
          <b-icon icon="envelope-fill" font-scale="1.5" variant="light"></b-icon>
          <a class="link" href="mailto:hi@work.rise.ao"> hi@work.rise.ao</a>
        </p>
      </div>
      <div>
        <p>
          <b-icon font-scale="1.5" icon="linkedin" ></b-icon>
          <a class="link" target="_blank" href="https://www.linkedin.com/company/risework/about/"> RISE WORK</a>
        </p>
      </div>
      <!--
      <div>
        <ul>
          <li><a href="https://www.facebook.com"><img :src="require('@/assets/img/Grupo 735.svg')" alt="link facebook"></a></li>
          <li><a href="https://twitter.com/"><img :src="require('@/assets/img/Grupo 734.svg')" alt="link twitter"></a></li>
          <li><a href="https://www.linkedin.com"><img :src="require('@/assets/img/Grupo 736.svg')" alt="link linkedin"></a></li>
        </ul>
      </div>
      -->
    </section>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollMeTo (id) {
      let getPosition = document.getElementById(id).offsetTop
      window.scrollTo({ left: 0, top: getPosition, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped lang="scss">
  .footer{
    width: 100%;
    padding: 0 10%;
    display: flex;
    background: transparent;
    flex-direction: column;
    justify-content: flex-end;
    height: 400px;
    .link {
      color: #fafafa;
      text-decoration: none;
    }
    @media screen and (min-width: 768px){
      justify-content: flex-start;
      min-height: 580px;
    }
      @media(max-width:768px){
      }
    .home-links-wrapper{
      width: 190px!important;
      max-width: 190px!important;
    }
    @media(max-width: 1024px){
      padding: 0 5%;
    }
    .footer-wrapper{
      display: flex;
      padding-top: 150px;
      width: 100%;
      justify-content: space-between;
      @media(min-width:768px) and (max-width:1024px){
        padding-top: 150px!important;
      }
      @media(max-width:768px){
        height: 300px!important;
      }
      .links-container{
        @media(max-width:768px){
          display: none;
        }
        div{
          width: 130px;
          max-width: 150px;
        }
        display: flex;
        h4{
          text-align: left;
          text-transform: capitalize;
          margin-bottom: 15px;
          color: #fff;
        }
        p{
          text-transform: capitalize;
          font-size: 14px;
          text-align: left;
          padding: 0 ;
          color: #fff;
          font-weight: lighter;
          cursor: pointer;
          font-family: "NexaLight",sans-serif;
        }
      }
    }
    .logo-container{
      height: 180px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      @media(max-width:768px){
        width: 100%;
        align-items: center;
        height: auto!important;
      }
      .logo{
        display:flex;
        flex-direction: column;
        align-items: flex-end;
        img{
          width: 200px;
        }
        @media(max-width: 1024px){
           align-items: center;
           img{
             width: 120px;
           }
        }
        @media(max-width: 1024px){
          img{
            width: 180px;
          }
        }
        p{
          color: #FEAA08;
          font-size: 16px;
          padding: 4px 0;
          font-family: "NexaLight",sans-serif;
        }
      }
    }
    .contacts{
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 0;
      margin: 0;
      @media(max-width:768px){
        margin: 20px 0!important;
        align-items: center;
        height: auto;
        padding: 5px;
        p{
          padding: 0;
        }
      }
      p{
        color: #fff;
      }
    }
    .copyright-wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      justify-content: space-between;
      &> div {
        margin: 30px 0;
        p {
          display: flex;
          align-items: center;
          .link {
            margin-left: 6px;
          }
        }
      }
      @media(max-width:768px){
        flex-direction: column-reverse;
        margin-bottom: 20px 0!important;
        height: auto;
        ul{
          width: 100%;
          justify-content: center;
          padding: 0;
          margin: 5px;
        }
      }
      div{
        p{
          color: #fff;
        }
      }
      ul{
        display: flex;
        li{
          list-style: none;
          padding: 0 10px;
          color: #fff;
        }
      }
    }
  }
</style>
