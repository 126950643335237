<template>
  <label :for="'btn-radio'+ item.id" class="btn-circle">
    <input v-bind="$attrs" v-on="$listeners" type="radio" name="profissional-or-business" :id="'btn-radio' + item.id" @change="$emit('checked', item)">
    <span class="circle"></span>
    <span class="title">{{item.nome}}</span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'button-radio',
  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
.btn-circle {
  display: inline-flex;
  align-items: center;
  input[type=radio] {
    display: none;
  }
  input:checked + .circle::before {
    background-color: #6a4c7cc0;

  }
  .circle::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 100px;
  }
  .circle {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    border: 1px solid #6a4c7cc0;
  }
  .title {
    margin-left: 6px;
    cursor: pointer;
  }
}
</style>
