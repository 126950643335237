<template>
  <div class="hero">
    <transition name="modal">
      <confirm-modal ModalTitle="Precisa de ajuda ?" v-if="toggleModalNewsletters" :buttonClose="true" @close-modal="toggleModal">
        <div class="form-newsletters">
          <ul class="newsletters-list">
            <li>
              <app-input
              ref="nome"
              class="font-small"
              placeholder="Nome"
              :error="errors['nome']"
              v-model="$v.newsletters.nome.$model"
              />

            </li>
            <li>
              <app-input-phone
              placeholder="Telemóvel (+244)"
              class="font-small"
              :error="errors['telefone']"
              :status="newsletters.telefone"
              maxlength="9"
              @input="newsletters.telefone = $event"
              v-model="$v.newsletters.telefone.$model"
              :phoneNumber="$v.newsletters.telefone.$model"
              :rules="[{ minLength: 9 }]"
              />

            </li>
            <li>
              <app-input
              placeholder="E-mail"
              :error="errors['email']"
              ref="email"
              class="font-small"
              v-model="$v.newsletters.email.$model"
              />
            </li>
            <li>
              <group-button-radio :items="newslettersBisness">
                <template #default="item">
                  <button-radio :checked="newsletters.tipoEntidade === item.nome" :item="item" @checked="selectedTypeNewsletters"/>
                </template>
              </group-button-radio>
            </li>
            <li>
              <transition name="empresa">
                <app-input v-show="newsletters.tipoEntidade && newsletters.tipoEntidade === 'Empresa'"
                :error="errors.tipoEntidade"
                placeholder="Empresa"
                class="font-small"
                v-model="$v.newsletters.nomeEmpresa.$model"
                />
              </transition>
            </li>
            <li>
              <textarea
              rows="4"
              v-model="$v.newsletters.descricao.$model"
              placeholder="Mensagem"
              class="newsletters-msg"
              :class="{error: errors.descricao}"
              />
            </li>
            <li>
              <button type="submit" @click="submeter">
                Enviar
              </button>
            </li>
          </ul>
        </div>
      </confirm-modal>
    </transition>
    <transition name="modal">
      <confirm-modal style="z-index: 999" v-if="responseHelp" ModalTitle="Obrigada pelo seu pedido de ajuda. Será contactado em breve pela RISE WORK." @confirm="successful"/>
    </transition>
    <div class="content">
      <section @click="goToLogin" class="card-candidate">
        <img src="@/assets/img/employee_yellow.svg" alt="" srcset="">
        <p>Procuras uma oportunidade de trabalho?<br><span style="text-transform: uppercase; font-weight: 400">Inscreve-te aqui</span></p>
      </section>
    </div>
    <section class="here-bg">
      <figure class="here-future">
        <img src="@/assets/img/home-hero-bg.png" alt="" ref="homeherobg">
        <figcaption>
          <h1>O FUTURO</h1>
          <span class="text-wrapper-span">AQUI.</span>
        </figcaption>
      </figure>
    </section>
    <span class="btn-newsletters" @click="toggleModal"><img src="@/assets/img/Grupo-1582.svg" alt=""></span>
    <container-card class="container-card">
      <toobar-search hide-employee="flex" hide-comapay="flex" input-background="#FACA63" :placeholder-input="placeHolderText" />
    </container-card>
    <sign-in-out-modal
      v-if="confirmModal"
      @close="confirmModal = false"
    />
  </div>
</template>

<script>
import { required, email, maxLength, minLength } from 'vuelidate/lib/validators'
import ToobarSearch from '@/components shared/ToobarSearch/toobar-search'
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'
import { requestHelp } from '@/api/services/help'
import SignInOutModal from '@/components shared/Modal/ConfirmModalSignOut'
import ContainerCard from '@/components shared/container-card'
import imageBakcgroundMobile from '@/assets/img/home-hero-bg2-mobile.png'
export default {
  name: 'Hero',
  components: {
    'toobar-search': ToobarSearch,
    'sign-in-out-modal': SignInOutModal,
    'container-card': ContainerCard,
    'confirm-modal': require('@/components shared/Modal/ConfirmModal2.vue').default,
    'app-input': require('@/components/Form/input-form.vue').default,
    'app-input-phone': require('@/components/Form/input-phone-mask.vue').default,
    'button-radio': require('@/components/Form/button-radio.vue').default,
    'group-button-radio': require('@/components/Form/group-button-radio.vue').default
  },
  data () {
    return {
      confirmModal: false,
      placeHolderText: '',
      toggleModalNewsletters: false,
      newslettersBisness: [
        {
          id: 1,
          nome: 'Profissional'
        },
        {
          id: 2,
          nome: 'Empresa'
        }
      ],
      errors: {
        nome: '',
        email: '',
        telefone: '',
        descricao: false,
        tipoEntidade: ''
      },
      newsletters: {
        tipoEntidade: 'Profissional',
        nome: '',
        email: '',
        telefone: '',
        nomeEmpresa: '',
        descricao: ''
      },
      responseHelp: null
    }
  },
  validations: {
    newsletters: {
      nome: {
        required
      },
      email: {
        required,
        email
      },
      telefone: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      },
      descricao: {
        required,
        minLength: minLength(3)
      },
      nomeEmpresa: {
        required
      }
    }
  },
  created () {
    const ScreenSize = window.screen.width
    if (ScreenSize > 768) {
      this.placeHolderText = 'Pesquise por função, sector ou experiência o Profissional que está à procura'
    }
    if (isAuthenticated()) {
      this.verifyAuthProfissional = true
    }
    if (isAuthenticatedParceiro()) {
      this.verifyAuthParceiro = true
    }
  },
  mounted () {
    if (window.document.body.offsetWidth < 768) {
      this.$refs.homeherobg.src = imageBakcgroundMobile
    }
  },
  methods: {
    successful () {
      this.responseHelp = null
      this.toggleModalNewsletters = false
    },
    restoreForm () {
      this.errors.nome = ''
      this.errors.email = ''
      this.newsletters = {
        tipoEntidade: 'Profissional',
        nome: '',
        email: '',
        telefone: '',
        nomeEmpresa: '',
        descricao: ''
      }
    },
    submeter () {
      const { nome, email, telefone, descricao, nomeEmpresa } = this.$v.newsletters
      this.errors.nome = ''
      this.errors.email = ''
      this.errors.telefone = ''
      this.errors.descricao = false
      this.errors.tipoEntidade = ''
      if (nome.$model === '' || nome.$error) {
        this.errors['nome'] = 'Nome é obrigatório'
        return
      }
      if (!telefone.$model || telefone.$error || telefone.$invalid || telefone.$model[0] !== '9') {
        this.errors['telefone'] = 'Número de telefone inválido'
        return
      }
      if (email.$error || email.$invalid) {
        this.errors['email'] = 'E-mail é obrigatório'
        return
      }
      if (this.newsletters.tipoEntidade === 'Empresa' && (!nomeEmpresa.$model || nomeEmpresa.$error || nomeEmpresa.$invalid)) {
        this.errors.tipoEntidade = 'Nome da empresa é obrigatório'
        return
      }

      if (descricao.$error || descricao.$invalid) {
        this.errors['descricao'] = true
        return
      }
      requestHelp(this.newsletters).then(data => {
        this.responseHelp = data
        this.toggleModalNewsletters = false
        this.restoreForm()
      })
    },
    selectedTypeNewsletters (item) {
      this.newsletters.tipoEntidade = item.nome
    },
    toggleModal () {
      this.toggleModalNewsletters = !this.toggleModalNewsletters
    },
    goToLogin () {
      if (isAuthenticatedParceiro()) {
        this.confirmModal = true
      } else if (!isAuthenticatedParceiro() && !isAuthenticated()) {
        this.$router.push({ name: 'Login' })
      } else if (isAuthenticated()) {
        this.$router.push({ name: 'MyAreaProfissional' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.empresa-enter-active, .empresa-leave-active {
  transition: opacity .3s ease-out;
}
.empresa-enter, .empresa-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.modal-enter-active, .modal-leave-active {
  transition: opacity .2s ease-out;
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.container-card {
  position: relative;
}
.here-bg {
  position: absolute;
  bottom: 0;
  @media screen and (min-width: 768px) {
    left: 50%;
    transform: translate(-50%, 0);
  }
  figure {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
    figcaption {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1600 - 300)));
      transform: translate(calc(130px + 200 * ((100vw - 300px) / (1600 - 300))), -180px);
      @media screen and (orientation: landscape) and (min-width: 1000px) {
        transform: translate(calc(100px + 200 * ((100vw - 300px) / (1600 - 300))), -80px);
      }
      h1 {
        font-size: 22pt;
        @media screen and (min-width: 768px) {
          font-size: calc(0.5em + 0.16rem * ((100vw - 300px) / (1600 - 300)));

        }
        font-family: "NexaBold", sans-serif;
        font-weight: 900;
        width: 46px;
        font-weight: bolder;
        text-align: left;
        word-break: break-all;
      }
      .text-wrapper-span{
        font-family: "NexaLight", sans-serif!important;
        font-size: calc(0.6em + 0.3em * ((100vw - 300px) / (1600 - 300)));
        font-weight: 100;
        word-break: keep-all;
      }
      @media screen and (min-width: 768px) {
        align-items: center;
        h1 {
          width: auto;
        }
        .text-wrapper-span {
          font-size: 2.7em;
          line-height: 0.8em;
        }
      }
    }
  }
}
.form-newsletters {
  width: 100%;
  .newsletters-list {
    list-style: none;
    padding-left: 0;
    width: 100%;
    li {
      margin: 5px 0;
    }
  }
  .newsletters-msg {
    width: 100%;
    border-radius: 4px;
    padding: 0.6em;
    border: 2px solid #4B335A;
    font-weight: 100;
    font-family: 'NexaLight';
    resize: none;
    z-index: 3;
    &.error {
      border: 2px solid red;
    }
    &::placeholder {
      color: rgba(121, 113, 113, 0.377);
    }
  }
  button[type=submit] {
    outline-style: none;
    background-color: #4B335A;
    color: #fafafa;
    border-style: none;
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color .05s 0s linear;
    &:active {
      background-color: #ffc700;
      color: #000;
    }
  }
}
.btn-newsletters {
  position: absolute;
  bottom: 190px;
  z-index: 3;
  @media screen and (min-width: 975px) {
    bottom: 140px;
  }
  right: 0;
  color: #fafafa;
  cursor: pointer;
}
.my-area-wraper{
  position: absolute;
  top: 60px;
  height: auto;
  list-style: none;
  right: 30%;
}
.my-area-hero{
  text-transform: uppercase;
  color: #fff;
  padding: 20px;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
}
@media(max-width:1950px){
  .hero{
    justify-content: center;
  }
}
@media(max-width:1440px){
  .card-candidate{
    width: 12rem!important;
    height: 12rem!important;
    cursor: pointer;
    img{
      width: 60px!important;
      padding-bottom: 10px;
    }
    p{
      font-size: 12px!important;
    }
  }
}
.hero{
  background-color: #ffc700;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    .text-wrapper h1 {
      display: none;
    }
  }
  @media(max-width: 1024px){
    .text-wrapper{
      h1{
        visibility: hidden;
      }
    }
  }
}
.content{
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  @media(max-width: 768px){
    flex-direction: column!important;
    align-items: center!important;
    justify-content: flex-start!important;
  }
}
.toolbar{
  width: 100%;
}
.card-candidate{
  cursor: pointer;
  margin-top: -2%;
  width: 15rem;
  height: 15rem;
  background: #4B335A;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  left: 5%;
  border-radius: 10px;
  -webkit-box-shadow: 14px 13px 24px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: 14px 13px 24px -10px rgba(0,0,0,0.75);
  box-shadow: 14px 13px 24px -10px rgba(0,0,0,0.75);
  img{
    width: 80px;
    height: auto;
    padding-bottom: 10px;
  }
  p{
    padding: 0;
    color: #fff;
    font-size: 16px;
    max-width: 10em;
  }
  @media(max-width:768px){
    margin-top: 0!important;
    width: 74%!important;
    bottom: 12vh;
    margin: 0 auto!important;
    height: 100px!important;
    flex-direction: row!important;
    left: 13%!important;
    justify-content: space-between!important;
    padding:0 20px;
    img{
      width: 45px!important;
      padding-bottom: 0!important;
    }
    p{
      text-align: left;
      font-weight: lighter;
      color:#ffffff95;
      max-width: unset!important;
      padding-left: 10px;
    }
  }
}
</style>
