<template>
  <div class="testimony">
    <container-page>
      <section class="description-wrapper">
          <h1>O que dizem de nós?</h1>
      </section>
      <section class="slide-wrapper">
        <div class="slides-container">
          <div class="slide-controllers-wrapper">
            <div :class="{'opacity-small':depoimentos.length === 0}" class="testimony-controller-right swiper-button-next">
            </div>
            <div :class="{'opacity-small':depoimentos.length === 0}" class="testimony-controller-left swiper-button-prev">
            </div>
          </div>
            <!--<placeholder-testomony v-show="depoimentos.length === 0" />-->
            <swiper v-show="depoimentos.length !== 0" class="slides-testimony" :options="Options" ref="mySwiper">
              <!-- slides -->
              <swiper-slide class="slide" v-for="(item, index) in depoimentos" :key="index">
                <div class="circle-image">
                  <img :src="`https://work-api.rise.ao/storage/depoimentos/${item.id}.png`"  alt="" srcset="">
                </div>
                <div class="slide-content">
                  <h1 class="depoimento-d">{{item.autor}}</h1>
                  <h5>{{item.titulo}}</h5>
                  <div class="depoimento-m">
                    <h1>{{item.autor}}</h1>
                  </div>
                  <p>{{item.descricao}}</p>
                </div>
              </swiper-slide>
            </swiper>
            <div class="slide-controllers">
              <div class="pagination-testimony" slot="pagination"  v-show="depoimentos.length !== 0">
              </div>
            </div>
        </div>
      </section>
    </container-page>
  </div>
</template>

<script>
import { getDepoimentos } from '@/api/services/home-page'
// import PlaceholderTestomony from '@/components shared/PlaceholderCard/PlaceholderTestomony'
import ContainerPage from '@/components shared/container-card.vue'
export default {
  name: 'App-Testimony',
  mounted () {
    getDepoimentos().then(resp => {
      this.depoimentos = resp.data.object
    })
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  components: {
    // 'placeholder-testomony': PlaceholderTestomony,
    'container-page': ContainerPage
  },
  data () {
    return {
      depoimentos: [],
      showModal: false,
      Options: {
        pagination: {
          el: '.pagination-testimony',
          clickable: true
        },
        navigation: {
          nextEl: '.testimony-controller-right',
          prevEl: '.testimony-controller-left'
        },
        slidesPerView: 4,
        direction: 'horizontal',
        spaceBetween: 15,
        breakpoints: {
          1600: {
            slidesPerView: 4.3,
            spaceBetween: 25
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1.2,
            spaceBetween: 30
          }
        }
      },
      swiperSlidesTestimony: this.depoimentos
    }
  }
}
</script>

<style lang="scss" scoped>
  .testimony{
    margin-bottom: 160px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: transparent;
    @media(max-width:768px){
      height: auto!important;
      margin-bottom: 0;
    }
    .slides-container {
      position: relative;
    }
  }
  .description-wrapper{
    display: flex;
    justify-content:flex-start;
    width: 100%;
    h1{
      font-size: 40px;
      text-transform: uppercase;
      // text-transform: uppercase;
      text-align: left;
      font-weight: 900;
      color: #4B335A;
      font-family: "NexaBold",sans-serif;
    }
    @media(max-width:768px){
      padding-left: 10%;
    }
    @media(max-width:320px){
      margin-left: -32px;
      justify-content: center;
      h1{
        font-size: 30px;
        text-align: center;
      }
    }
  }
  .slides-testimony{
    padding: 30px 5px;
    width:100%;
    display: flex;
    align-items: stretch;
    @media(max-width:768px){
      padding-top: 50px;
    }
  }
  .slide{
    position: relative;
    background: #fff;
    border-radius: 14px;
    -webkit-box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.18);
    box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.18);
    display: flex;
    flex-direction: column;
    padding: 25px;
    box-sizing: border-box;
    margin: 41px 0 0;
    min-height: 200px;
    height: 290px;
    width: 100%;

    @media screen and (max-width: 768px) {
      height: 480px;
      padding: 25px 0px;
    }
    min-width: 230px;
  }
  .circle-image{
    position: absolute;
    right: 35px;
    top: -15px;
    img{
      width: 60px!important;
      height: 60px!important;
      border: 3px solid #4B335A;
      object-fit: cover;
      border-radius: 50%;
      -webkit-box-shadow: 0px 20px 5px 3px rgba(0,0,0,0.28);
      -moz-box-shadow: 0px 20px 5px 3px rgba(0,0,0,0.28);
      box-shadow: 0px 20px 35px -5px rgba(0,0,0,0.28);
    }
    @media(max-width:768px){
      margin:-70px 0 0 110px;
      img{
        width: 110px!important;
        height: 110px!important;
        border: 3px solid #4B335A;
      }
    }
    @media(max-width:320px){
     img{
        width: 90px!important;
        height: 90px!important;
        border: 3px solid #4B335A;
      }
    }
  }
  .slide-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .depoimento-m{
      display: none;
    }
    h1{
      font-size: 16px!important;
      & + h5 {
        margin-bottom: 8px;
        color: #313131d8;
        font-size: 0.86em;
        font-weight: 900;
      }
    }
    p{
      font-size: 14px!important;
    }
    @media(max-width:768px){
      padding:30px  35px;
      .depoimento-d{
        display: none;
      }
      .depoimento-m {
        height: 74px;
        display: flex;
        margin: 0!important;
        align-items: center;
      }
      h1{
        font-size: 18px!important;
        max-width: 90px;
        text-overflow: ellipsis;
        align-items: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
      p{
        color: #000000!important;
        line-height: 22px;
      }
    }
    h1{
      margin: 10px 0;
      font-family: "NexaBold", sans-serif;
      text-align: left;
      font-size: 27px;
      text-overflow: ellipsis;
      color: #4B335A;
    }
    p{
      font-family: 'RubikLight';
      color: #000;
      text-align: left;
    }
    button{
      border: none;
      border-radius: 36px;
      font-size: 16px;
      margin: 12px 0;
      padding: 8px 20px;
      background: #4B335A;
      color: #fff;
      cursor: pointer;
      font-family: "NexaLight",sans-serif,
    }
  }
  .slide-controllers-wrapper{
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70px;
      height: 100px;
      z-index: 999;
      justify-content: center;
      left: -40px;
      bottom: 10px;
      @media(max-width:768px){
        display: none;
      }
     .swiper-button-prev:after, .swiper-button-next:after {
        font-family: swiper-icons;
        font-size: 15px!important;
        font-weight: bolder;
        text-transform: none !important;
        letter-spacing: 0;
        color: #000!important;
        text-transform: none;
        font-variant: initial;
      }
     .testimony-controller-right{
        top: 2px;
       position: absolute;
        width: 48px;
        height: 48px;
        background: #fff;
        border-radius: 50%;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
        }
    }
    .testimony-controller-left{
       width: 38px;
        height: 38px;
        left: 16px;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        cursor: pointer;
        img{
          width: 15px;
          height: 15px;
        }
    }
  }
  .slide-wrapper{
    padding: 0;
  }
   .slide-controllers{
      display: none
  }
</style>
<style>
  .pagination-testimony{
    position: absolute;
    right: 10%;
  }
  .pagination-testimony.swiper-pagination-bullet-active{
    background:#fff !important;
    opacity: 1!important;
  }
  .pagination-testimony.swiper-pagination-bullet {
      width: 12px;
      margin: 0 3px;
      height: 12px;
      display: inline-block;
      border-radius: 100%;
      background: #4B335A;
      opacity: 1!important;
      border: #fff solid 3px!important;
  }
</style>
