<template>
<div data-picklist :class="[color]" tabindex="0" @keyup="handlerKeyUp" role="listbox" class="container-datalist">
  <p class="title">{{title}}</p>
  <div
  class="input-control"
  :class="{ [color]: true, [borderStyle]: true }"
  data-input
  >
    <label class="value-input" :class="{placeholder: !value}">{{value || placeholder}}</label>
    <template v-if="isShow && dataSource.length !== 0">
      <widget-datalist class="cover align-start" :class="{[align]: true, separator: true, 'full-width': true}" :fieldName="fieldName" :data="dataSource" :value="value" @setData="setData" :themeStyle="themeStyle"/>
    </template>
    <span @click="toggleWidget" data-input :style="{color: btnColor || 'orange'}">{{!isShow ? '+' : '-'}}</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'input-datalist-filter',
  components: {
    'widget-datalist': require('@/components/Form/widget-datalist').default
  },
  props: {
    btnColor: String,
    placeholder: String,
    value: [String, Object],
    dataSource: Array,
    themeStyle: Object,
    fieldName: String,
    title: String,
    topAlign: Boolean,
    borderStyle: [Boolean, String],
    align: {
      type: String,
      default: window.innerWidth > 768 ? 'top' : 'bottom'
    },
    color: {
      type: String,
      default: 'primary'
    },
    _isShow: {
      type: Boolean,
      default: false
    },
    field: [String, Number]
  },
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    handlerKeyUp (e) {
      if (e.keyCode === 13) {
        this.toggleWidget()
      }
    },
    toggleWidget () {
      if (this.dataSource.length) {
        this.isShow = !this.isShow
      }
    },
    setData (value) {
      const timerID = setTimeout(() => {
        this.$emit('setData', value)
        this.isShow = false
        clearTimeout(timerID)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.mt .title {
  margin-top: 0;
}
.primary {
  color: #6a4c7cc0;
}
.cover {
  padding: 0 20px;
}
.container-datalist {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  &.w-400 {
    @media screen and (min-width: 600px){
      width: 400px;
    }
  }
  .value-input {
    color: #fafafa;
  }
  &.medium {
    .input-control {
      height: 52px;
    }
  }
  &.fs-small .value-input {
    font-size: 14px !important;
  }
  &.primary-color {
    .input-control {
      label {
        color: #6A4C7C;
      }
    }
  }
}
.title {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 18px;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
.top {
  bottom: 60px;
  left: 0;
  top: initial;
}
.bottom {
  bottom: initial;
  left: 0;
  top: 60px;
}
.input-control {
  position: relative;
  background-color: transparent;
  border: 2px solid;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  &.border-white {
    border-color: #fff;
  }
  &.disabled {
    cursor: not-allowed;
    label {
      pointer-events: none;
    }
  }
  label {
    width: 100%;
    flex: 1;
    border-style: none;
    outline-style: none;
    font-size: 16px;
    background: transparent;
    text-align: left;
    color: inherit;
    display: flex;
    align-items: center;
    color: #fff;
  }
  label {
    font-weight: 100;
    font-family: 'NexaLight';
    @media(max-width:560px){
      font-size: 16px!important;
    }
  }
  span {
    font-size: 1.5rem;
    font-weight: 900;
    color: orange;
    button {
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: 900;
      color: orange;
      background-color: transparent;
      border-style: none;
    }
  }
}
</style>
